<template>
  <v-card class="elevation-12" :loading="loading">
    <v-toolbar color="info" dark flat>
      <v-toolbar-title>
        <span>Reset Password</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on: on }">
          <v-btn icon v-on="on" :to="{ name: 'login' }">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Cancel") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <v-form
        v-model="validAnswersForm"
        @submit.stop.prevent="onSubmitAnswers"
        v-if="!loading && validKey && !validAnswers && !validPassword"
      >
        <v-container>
          <p>
            Please answer the following security questions before resetting your password:
          </p>
          <v-row>
            <v-col>
              <v-text-field
                v-for="(item, index) in form.securityQuestions"
                :key="index"
                v-model="form.securityAnswers[index].answer"
                :rules="rules.securityAnswer"
                :label="form.securityQuestions[index].question"
                :name="'securityAnswer' + index"
                prepend-icon="mdi-help-circle-outline"
                type="text"
                class="required"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn type="submit" color="primary" :loading="loading" :disabled="!validAnswersForm"
                >Verify Answers</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-form
        v-model="validPasswordForm"
        @submit.stop.prevent="onSubmitPassword"
        v-if="!loading && validKey && validAnswers && !validPassword"
      >
        <v-container>
          <p>Please enter a new password which includes:</p>
          <ul>
            <li v-if="programGroup && programGroup.passwordMinimumCharacters">
              Minimum length of
              {{ programGroup.passwordMinimumCharacters }} characters
            </li>
            <li v-if="programGroup && programGroup.passwordMinimumDigits">
              Include at least {{ programGroup.passwordMinimumDigits }}
              {{ programGroup.passwordMinimumDigits == 1 ? "number" : "numbers" }}
            </li>
            <li v-if="programGroup && programGroup.passwordMinimumAlpha">
              Include at least {{ programGroup.passwordMinimumAlpha }}
              {{ programGroup.passwordMinimumDigits == 1 ? "letter" : "letters" }}
            </li>
            <li v-if="programGroup && programGroup.passwordMinimumSpecial">
              Include at least {{ programGroup.passwordMinimumSpecial }}
              {{ programGroup.passwordMinimumSpecial == 1 ? "special character" : "special characters" }}
              ({{ programGroup.passwordAllowableSpecialCharacters }})
            </li>
            <li v-if="programGroup && programGroup.passwordPreviousPasswordCheck">
              Not the same as a previous password
            </li>
            <li v-if="programGroup && programGroup.passwordUsernameCheck">
              Not the same as your username
            </li>
          </ul>
          <v-row>
            <v-col>
              <PasswordField
                name="newPassword"
                label="New Password"
                id="newPassword"
                v-model="form.newPassword"
                :rules="rules.newPassword"
                :error-messages="$error.getValidationError(errors, 'password')"
                @input="$error.clearValidationError(errors, 'password')"
                :programGroup="programGroup"
              ></PasswordField>
              <PasswordField
                name="confirmPassword"
                label="Confirm Password"
                id="confirmPassword"
                type="password"
                v-model="form.confirmPassword"
                :rules="rules.confirmPassword"
                :error-messages="$error.getValidationError(errors, 'confirmPassword')"
                @input="$error.clearValidationError(errors, 'confirmPassword')"
                :programGroup="programGroup"
                :passwordMinimumCharacters="1"
                :passwordMinimumDigits="0"
                :passwordMinimumAlpha="0"
                :passwordMinimumSpecial="0"
                passwordAllowableSpecialCharacters=""
              ></PasswordField>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn type="submit" color="primary" :loading="loading" :disabled="!validPasswordForm"
                >Change Password</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-container v-if="!loading && validKey && validAnswers && validPassword">
        <h3>Password changed</h3>
        <p>
          Your password has been changed successfully! You'll be redirected in 5 seconds to the login page. If not
          <router-link :to="{ name: 'login' }">click here</router-link>.
        </p>
      </v-container>
      <v-container v-if="!loading && !validKey">
        <h3>Wrong or expired key</h3>
        <p>
          If you forgot your password click the Forgot Password link in the login page. You will be redirected in 10
          seconds. If not
          <router-link :to="{ name: 'login' }">click here</router-link>.
        </p>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import PasswordField from "../fields/PasswordField.vue";
import ApiError from "../display/ApiError";

export default {
  name: "ResetPasswordForm",
  metaInfo: {
    title: "Reset Password"
  },
  components: {
    ApiError,
    PasswordField
  },
  data() {
    return {
      validAnswersForm: false,
      validPasswordForm: false,
      loading: true,
      buttonText: "Verify Answers",
      tab: 0,
      form: {
        key: 0,
        securityQuestions: [],
        securityAnswers: []
      },
      title: "Please answer your security questions",
      errors: {},
      rules: {
        newPassword: [
          v => !!v || "New password is required.",
          v => v.length >= 8 || "New password must be a minimum of 8 characters"
        ],
        confirmPassword: [v => !!v || "New password is required."],
        securityAnswer: [v => !!v || "An answer is required."]
      },

      programGroup: null,

      validKey: false,
      validAnswers: false,
      validPassword: false
    };
  },
  mounted() {
    // clear existing errors
    this.loading = true;
    this.$store.dispatch("logout");
    this.form.key = this.$route.query.key;

    this.$api
      .post("/api/users/checkKeyAndGetSecurityQuestions", this.form)
      .then(({ data }) => {
        this.validKey = true;
        this.form = data;
        this.programGroup = data.programGroup;
        if (this.form.securityQuestions && this.form.securityQuestions.length > 0) {
          this.form.securityQuestions.forEach((x, index) => {
            this.form.securityAnswers[index] = {
              securityQuestion: {
                id: x.id
              },
              answer: ""
            };
          });
        } else {
          this.validAnswers = true;
        }
      })
      .catch(() => {
        this.validKey = false;
        setTimeout(() => {
          this.$router.push({ name: "login" });
        }, 10000);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    onSubmitAnswers() {
      if (!this.validAnswersForm) {
        return;
      }

      this.loading = true;
      this.validAnswers = false;
      this.errors = {};

      if (this.form.programGroup && this.form.programGroup.id) {
        this.form.programGroup = { id: this.form.programGroup.id };
      }

      this.$api
        .post("/api/users/checkSecurityAnswers", this.form)
        .then(() => {
          this.loading = false;
          this.validAnswers = true;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmitPassword() {
      this.loading = true;
      this.validPassword = false;
      this.errors = {};
      this.form.sendEmail = true;

      if (this.form.programGroup && this.form.programGroup.id) {
        this.form.programGroup = { id: this.form.programGroup.id };
      }

      this.$api
        .post("/api/users/changePasswordFromReset", this.form)
        .then(() => {
          this.validPassword = true;
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 5000);
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
